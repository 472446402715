@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system,
    BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.iekbcc0.iekbcc9.ju367v6x.ju367v7i.ju367v93.ju367vn.ju367vdr.ju367vec.ju367v11.ju367v1c.ju367v2b.ju367v8i._12cbo8i3.ju367v8g._12cbo8i4._12cbo8i6 {
  background: #a253f7;
}

.Transaction-tooltip {
  margin-top: 50px !important;
}

.receiptparagraph {
  word-break: break-all;
  white-space: normal;
}
